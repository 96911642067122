<template>
  <div class="mx-4 my-4 md:mx-8">
    <Breadcrumb :links="breadcrumb" />
    <br />
    <h1 class="text-3xl mb-5 ml-3">{{ this.form._id ? `Ticket: ${this.form.numero}` : "Suporte: Abra seu ticket" }}</h1>

    <div class="mt-5 md:mt-0 md:col-span-2">
      <form action="#" method="POST" autocomplete="off">
        <div class="px-5 pb-4">
          <div class="grid grid-cols-12 gap-6">
            <div class="col-span-12 md:col-span-7">
              <label for="titulo" class="block text-sm font-medium">Titulo*</label>
              <input
                :disabled="this.form._id ? true : false"
                placeholder="Escreva um breve resumo do seu problema."
                v-model="form.titulo"
                type="text"
                name="titulo"
                id="titulo"
                maxlength="80"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              />
            </div>

            <div class="col-span-12 md:col-span-6">
              <label for="centroscusto" class="block text-sm font-medium text-gray-700">Categoria*</label>
              <v-select
                :disabled="this.form._id ? true : false"
                v-model="form.categoria"
                :options="[
                  { nome: 'Falha de envio massivo', _id: 'Falha de envio massivo' },
                  { nome: 'Falha na iniciação manual', _id: 'Falha na iniciação manual' },
                  { nome: 'Retorno não presentes na plataforma', _id: 'Retorno não presentes na plataforma' },
                  { nome: 'Problemas de relatório ou status', _id: 'Problemas de relatório ou status' },
                  { nome: 'Outros', _id: 'Outros' }
                ]"
                label="nome"
                :reduce="(c) => c._id"
                name="categoria"
                id="categoria"
                class="mt-1"
              >
              </v-select>
            </div>

            <div class="col-span-12 md:col-span-6">
              <label for="centroscusto" class="block text-sm font-medium text-gray-700">Prioridade*</label>
              <v-select
                :disabled="this.form._id ? true : false"
                v-model="form.prioridade"
                :options="[
                  { nome: 'Alta', _id: 'Alta' },
                  { nome: 'Média', _id: 'Média' },
                  { nome: 'Baixa', _id: 'Baixa' }
                ]"
                label="nome"
                :reduce="(c) => c._id"
                name="prioridade"
                id="prioridade"
                class="mt-1"
              ></v-select>
            </div>

            <div class="col-span-12 md:col-span-6">
              <label for="descricao" class="block text-sm font-medium">Descrição*</label>
              <textarea
                :disabled="this.form._id ? true : false"
                placeholder="Descreva seu problema com mais detalhes."
                v-model="form.descricao"
                rows="4"
                type="text"
                name="descricao"
                id="descricao"
                class="mt-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              ></textarea>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-2 grap-6">
          <div class="px-5 py-3 text-left sm:px-6">
            <router-link
              :to="`/suporte`"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              Voltar
            </router-link>
          </div>
          <div class="px-5 py-3 text-right sm:px-6">
            <button
              v-if="!this.form._id"
              @click="save"
              type="button"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-green-400"
            >
              Salvar
            </button>
            <button
              v-else
              @click="toggleComentario"
              type="button"
              class="inline-flex justify-center py-2 px-6 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-500 hover:bg-green-600 focus:bg-green-600"
            >
              + Comentário
            </button>
          </div>
        </div>
        <div class="px-5 py-3">
          <div v-if="showImputComentario" class="grid grid-cols-12 gap-4">
            <div class="col-span-12 md:col-span-12 justify-end" ref="comentarioDiv">
              <label for="comentarios" class="block text-sm font-medium">Adicionar o comentário</label>
              <textarea
                placeholder="Adicione um novo comentário."
                v-model="newcomentario"
                rows="2"
                type="text"
                name="comentario"
                id="comentario"
                class="mt-1 mb-1 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
              ></textarea>
              <button
                @click="toggleComentario"
                type="button"
                class="bg-gray-600 hover:bg-gray-700 focus:shadow-sm mt-0.5 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center"
              >
                Cancelar
              </button>
              <button
                @click="comment"
                type="button"
                class="bg-green-500 hover:bg-green-600 focus:bg-green-700 focus:shadow-sm mx-2 mt-0.5 focus:ring-opacity-50 text-white py-1 px-2 rounded-md text-sm shadow-sm hover:shadow-md font-semibold text-center"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div v-if="this.form.comentarios.length > 0" class="px-5 flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Data</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Remetente</th>
                  <th scope="col" class="px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Comentarios</th>
                </tr>
              </thead>
              <tbody class="bg-white divide-y divide-gray-200">
                <tr v-for="comentario in form.comentarios.slice().reverse()" :key="comentario._id" :class="comentario.root ? 'bg-green-100' : ''">
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    <span class="inline-block font-medium text-blue-700">{{ moment(comentario.data).format("DD/MM HH:mm") }}</span>
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3 whitespace-nowrap">
                    <span class="inline-block font-medium text-gray-800">{{ comentario.root ? `Admin - ${comentario.userName}` : `User - ${comentario.userName}` }}</span>
                  </td>
                  <td class="text-sm 2xl:text-base px-2 py-3">
                    <span class="inline-block text-gray-800">{{ comentario.conteudo }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumbs.vue";
import moment from "moment";

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      moment,
      breadcrumb: [
        { url: "/suporte", value: "Suporte" },
        { url: "/suporte/ticket", value: "Ticket" }
      ],
      newcomentario: "",
      form: {
        titulo: "",
        descricao: "",
        categoria: "",
        prioridade: "",
        numero: "",
        comentarios: []
      },
      showImputComentario: false
    };
  },

  computed: {
    isFormValid() {
      return this.form.titulo !== "" && this.form.descricao !== "" && this.form.categoria !== "" && this.form.prioridade !== "";
    }
  },

  methods: {
    async save() {
      if (!this.isFormValid) {
        this.$vToastify.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }

      const method = this.form._id ? "put" : "post";
      const req = await this.$http[method](`/v1/ticket`, this.form);

      if (req.data.success) {
        this.$vToastify.success("Salvo com sucesso!");
        this.$router.push({ path: `/suporte` });
      } else {
        this.$vToastify.error(req.data.err);
      }
    },

    async comment() {
      if (!this.newcomentario) {
        this.$vToastify.error("Por favor, escreva algum comentario.");
        return;
      }

      const req = await this.$http.put(`/v1/ticket/${this.form._id}/comentario`, { conteudo: this.newcomentario });

      if (req.data.success) {
        this.$vToastify.success("Salvo com sucesso!");

        const req = await this.$http.get(`/v1/ticket/${this.form._id}`);
        this.form = req.data;
        this.newcomentario = "";
        this.showImputComentario = false;
      } else {
        this.$vToastify.error(req.data.err);
      }
    },

    toggleComentario() {
      this.showImputComentario = !this.showImputComentario;

      if (this.showImputComentario) {
        this.$nextTick(() => {
          this.$refs.comentarioDiv.scrollIntoView({ behavior: "smooth" });
        });
      }
    }
  },

  async beforeMount() {
    const id = this.$route.params.id;

    if (id) {
      const req = await this.$http.get(`/v1/ticket/${id}`);
      this.form = req.data;
    }
  }
};
</script>
